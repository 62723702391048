<template>
  <div>
    <el-radio-group v-model="value" @change="changeVal">
      <el-radio-button label>不限</el-radio-button>
      <el-radio-button v-for="(item,index) in options" :key="index" :label="item.codeName"></el-radio-button>
    </el-radio-group>
    <div class="children">
      <span v-if="value">子标签：</span>
      <el-checkbox-group v-model="checkboxVal" @change="changeText">
        <el-checkbox-button
          v-for="item in childrenArray"
          :label="item.codeName"
          :key="item.id"
        >{{item.codeName}}</el-checkbox-button>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import {
  getKnowTechList,
  getIndustryList,
  getKnowFieldList,
} from '@/api/common'
import { getProductType } from '@/api/product'
export default {
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
      default: '',
    },
    code: {
      // 选中的那一项的key
      type: String,
      default: '',
      require: true,
    },
    queryName: {
      // 需要给后端的字段
      type: String,
    },
    name: {
      // 前端展示的字段
      type: String,
    },
    tag: {
      type: String,
    },
  },
  watch: {
    code: function (newVal) {
      this.value = newVal

      if (!newVal) {
        this.getDicData()

        this.childrenArray = []
      }
    },
  },
  data() {
    return {
      options: [],
      value: '',
      checkboxVal: [],
      childrenArray: [],
      firstVal: '',
    }
  },
  // created() {
  //   this.getDicData()
  // },
  mounted() {
    this.getDicData()
  },
  methods: {
    async getDicData() {
      let res

      switch (this.tag) {
        case '行业':
          this.options = sessionStorage.getItem('industry')

          if (!this.options) {
            let { data: res } = await getIndustryList()

            res = JSON.parse(
              JSON.stringify(res).replace(/industryName/g, 'codeName')
            )

            sessionStorage.setItem('industry', JSON.stringify(res))
          }

          this.options = JSON.parse(sessionStorage.getItem('industry'))

          break

        case '领域':
          this.options = sessionStorage.getItem('field')

          if (!this.options) {
            let { data: res } = await getKnowFieldList()

            res = JSON.parse(
              JSON.stringify(res).replace(/fieldName/g, 'codeName')
            )

            sessionStorage.setItem('field', JSON.stringify(res))
          }

          this.options = JSON.parse(sessionStorage.getItem('field'))
          break

        case '技术':
          this.options = sessionStorage.getItem('tech')

          if (!this.options) {
            let { data: res } = await getKnowTechList()

            res = JSON.parse(
              JSON.stringify(res).replace(/techName/g, 'codeName')
            )

            sessionStorage.setItem('tech', JSON.stringify(res))
          }

          this.options = JSON.parse(sessionStorage.getItem('tech'))

          break

        case '产品':
          res = await getProductType({ code: '021' })
          this.options = res.data
          this.options = JSON.parse(
            JSON.stringify(this.options).replace(/codeName/g, 'codeName')
          )
          break

        default:
          break
      }

      if (this.code) {
        this.options.forEach((item) => {
          if (item.codeName === this.code) {
            this.value = item.codeName
          }
        })
      }
    },
    changeText(val) {
      this.$emit('onChange', this.firstVal, this.queryName, this.name, val)
    },

    changeVal(val) {
      this.firstVal = val

      this.checkboxVal = []

      this.childrenArray = []

      if (val) {
        this.options.forEach((el) => {
          if (el.codeName === val) {
            this.childrenArray = el.childNode
          }
        })
      } else {
        this.childrenArray = []
      }

      this.$emit('onChange', this.firstVal, this.queryName, this.name)

      this.$emit('update:code', val)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-checkbox-group {
  background-color: #f5f6f7;
}
.children {
  background-color: #f5f6f7;
  display: flex;
}
span {
  color: #606266 !important;
  padding-left: 20px;
  display: inline-block;
  min-width: 60px;
}
</style>
